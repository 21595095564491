/*
  import { actionItemNoteStore } from '@/services/BoardActionItems/ActionItemNotes/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { actionItemNote } = await actionItemNoteStore.dispatch ('getActionItemNoteById', {
      actionItemNoteID
    });
  */

  async getActionItemNoteList({}, { actionItemID }) {
    console.log('in getActionItemNoteList...' + actionItemID)
    try {
      const results = await kms.get('/BoardMeetings/ActionItemNote/List', {
        params: {
          actionItemID
        }
      })

      if (isDebug == true) console.debug('getActionItemNoteList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The notes list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getActionItemNoteById({}, { actionItemNoteID }) {
    try {
      console.debug('in getActionItemNoteById...')
      const note = await kms.get(`/BoardMeetings/ActionItem/${actionItemNoteID}`)

      if (isDebug == true) console.debug('getActionItemNoteById=' + JSON.stringify(note))

      return {
        result: note
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the note.`)
      console.error(exception)
    }

    return {
      note: {}
    }
  },

  // Update
  async updateActionItemNote({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/BoardMeetings/ActionItemNote`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.actionItemNoteID > 0) {
        notifyMessage(`Successfully updated the note.`)
        done()
      } else {
        notifyError('There was a problem updating this note.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this note.`)
      console.error(exception)
    }
  },

  // Add
  async addActionItemNote({ dispatch }, { actionItemID, aspNetUserID, note, done }) {
    if (isDebug == true) console.debug('...in addActionItemNote')
    try {
      const results = await kms.post(`/BoardMeetings/ActionItemNote`, {
        actionItemID,
        aspNetUserID,
        note
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.actionItemNoteID > 0) {
        notifyMessage(`Successfully added the note.`)
        done()
      } else {
        notifyError('There was a problem adding this note.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this note.`)
      console.error(exception)
    }
  },

  //Delete
  async deleteActionItemNote({}, { actionItemNoteID, done }) {
    if (isDebug == true) console.debug('...in deleteActionItemNote=' + actionItemNoteID)
    try {
      const result = await kms.delete(
        `/BoardMeetings/ActionItemNote/${actionItemNoteID}?forceDelete=true`
      )

      if (result) {
        notifyMessage(`Successfully deleted the note.`)
        done()
      } else {
        notifyError('There was a problem deleting this note.')
      }
    } catch (exception) {
      notifyError('There was a problem deleting this note.')
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const actionItemNoteStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
