<template>
  <PageContentLayoutOnly>
    <ModernTable :filters="filters" :rows="rows" :columns="columns" :pagination="pagination">
      <template v-slot:header>
        <div class="pb-5">
          <b-button @click="createNoteModal()" size="is-small" type="is-primary" rounded>
            Create Note
          </b-button>
          <b-button
            type="is-ghost"
            @click="toggleFilters"
            :style="styles.filterButton"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
    <Modal :toggle.sync="toggle">
      <NotesModal :actionItemID="parseInt(actionItemID)" :actionItemNote="selectedNote" />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import NotesModal from './components/NotesModal'
import Modal from '@/components/Modal'
import { mapState, mapGetters } from 'vuex'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'ActionItemNotesList',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    NotesModal,
    Modal
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,

  methods,

  mounted: function() {
    data, (this.loading = true)
    this.reload()

    this.loading = false
  },

  watch: {
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  i18n: {
    messages: {
      en: {
        actionItemNotesList: {
          title: 'Action Item Notes'
        }
      }
    }
  }
}
</script>

<style></style>
