import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import { actionItemNoteStore } from '@/services/BoardActionItems/ActionItemNotes/store'
import Button from '@/components/buttons/Button'

export const methods = {
  async confirmDeleteNote(id, note) {
    this.$buefy.dialog.confirm({
      title: 'Delete Action Item Note',
      message: `Are you sure you want to <b>delete</b> the Board action item note for "${note}"?`,
      confirmText: 'Delete Action Item Note',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => await this.deleteNote(id)
    })
  },

  async deleteNote(id) {
    await actionItemNoteStore.dispatch('deleteActionItemNote', {
      actionItemNoteID: id,
      done: async () => {
        this.loading = false
        this.selectedNote = null
        await this.reload()
      }
    })
  },

  processNoteUpdate(note) {
    this.toggle = true
    this.actionItemNoteID = note.actionItemNoteID
    this.selectedNote = note
  },

  createNoteModal() {
    this.selectedNote = null
    this.toggle = true
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  determineRows: function(list) {
    const dateFormat = 'MM/DD/YYYY hh:mm a'

    let filteredList = list

    var rows = filteredList.map(entry => {
      const actionItemNoteID = _get(entry, 'actionItemNoteID', 0)
      const createdDate = parseDate(_get(entry, 'createdDate', null), dateFormat)
      const note = _get(entry, 'note', '')
      const author = _get(entry, 'authorIdentityInformation.name', '')
      const authenticatedId = _get(entry, 'authorIdentityInformation.aspNetUserID', false)

      const isAuthenticated = authenticatedId && authenticatedId != undefined ? true : false

      return {
        note,
        author,
        createdDate,
        edit: {
          component: Button,
          props: {
            onClick: () => {
              this.processNoteUpdate(entry)
            },
            text: 'Edit',
            disabled: !isAuthenticated,
            title: !isAuthenticated
              ? 'You do not have permission to change another person`s notes'
              : 'Edit'
          }
        },
        erase: {
          component: Button,
          props: {
            onClick: () => {
              this.confirmDeleteNote(actionItemNoteID, note)
            },
            text: 'Delete',
            disabled: !isAuthenticated,
            title: !isAuthenticated
              ? 'You do not have permission to delete another person`s notes'
              : 'Delete'
          }
        }
      }
    })

    this.rows = rows
  },
  async reload() {
    console.debug('in reload...')
    this.loading = true

    this.rows = []

    console.debug('params=' + JSON.stringify(this.$route.params))

    this.actionItemID = this.$route.params.id

    if (this.isDebug == true) console.debug('acionItemID=' + this.acionItemID)

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    await actionItemNoteStore
      .dispatch('getActionItemNoteList', {
        actionItemID: this.actionItemID
      })
      .then(({ list }) => {
        this.determineRows(list)
        this.loading = false
      })

    loadingComponent.close()

    this.loading = false
  }
}
