<template>
  <div class="section" notes-modal>
    <div class="container">
      <div class="is-size-4" v-if="noteViewOnly === true">View Action Item Note</div>
      <div class="is-size-4" v-else-if="buttonText == 'Save'">Update Action Item Note</div>
      <div class="is-size-4" v-else>Create Action Item Note</div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="column is-12" v-if="formData.author && formData.author != undefined">
                  <valid-input
                    style="width:50px !important;"
                    name="Author"
                    label="Author"
                    disabled="true"
                    type="text"
                    vid="Author"
                    placeholder="Author"
                    spellcheck="true"
                    aria-label="Author"
                    class="is-small"
                    v-model="formData.author"
                  />
                </div>
                <div class="column is-12">
                  <valid-input
                    style="width:50px !important;"
                    :disabled="noteViewOnly"
                    name="Note"
                    label="Note"
                    maxlength="8000"
                    type="textarea"
                    vid="Note"
                    placeholder="Note"
                    spellcheck="true"
                    aria-label="Note"
                    rules="max:8000|required"
                    class="is-small"
                    v-model="formData.note"
                  />
                </div>
                <div class="column is-12">
                  <span class="pb-5 pr-5" v-if="!noteViewOnly">
                    <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                      {{ buttonText }}
                    </button>
                  </span>
                  <span><b-button type="is-primary" @click="closeModal">Close</b-button></span>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'

export default {
  props: {
    actionItemID: Number,
    actionItemNote: Object,
    noteViewOnly: Boolean
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
