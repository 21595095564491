import { actionItemNoteStore } from '@/services/BoardActionItems/ActionItemNotes/store'

import $ from 'jquery'
import { notifyError } from '@/services/notify'
import _get from 'lodash/get'

export const methods = {
  async reload() {
    this.loading = true

    console.log(this.actionItemID + ' actionItemNote=' + JSON.stringify(this.actionItemNote))

    if (this.actionItemNote && this.actionItemNote != undefined) {
      //Update
      this.buttonText = 'Save'

      this.formData.actionItemNoteID = _get(this.actionItemNote, 'actionItemNoteID', 0)
      this.formData.author = _get(this.actionItemNote, 'authorIdentityInformation.name', '')
      this.formData.note = _get(this.actionItemNote, 'note', '')
      this.loading = false
    } else {
      this.buttonText = 'Add'
      this.loading = false
    }

    this.loading = false
  },

  open() {
    this.formData = {
      actionItemNoteID: 0,
      author: '',
      note: ''
    }
    this.isOpen = true
    this.loading = false
  },

  closeModal() {
    this.$emit('update:toggle', (this.toggle = !this.toggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async onFormSubmit() {
    if (this.formData && this.formData != undefined) {
      if (this.formData.actionItemNoteID && this.formData.actionItemNoteID > 0) {
        await this.updateNote()
      } else {
        await this.addNote()
      }
    }
  },

  async addNote() {
    if (!this.actionItemID || !this.formData || this.formData == undefined) {
      notifyError('There was a problem processing this note.')
      return
    }
    this.loading = true
    await actionItemNoteStore.dispatch('addActionItemNote', {
      actionItemID: this.actionItemID,
      aspNetUserID: 'temp',
      note: this.formData.note ? this.formData.note : '',
      done: () => {
        // auto-close modal
        this.$emit('update:toggle', (this.toggle = false))
        $('#clickclose').click()
        this.loading = false
      }
    })
  },

  async updateNote() {
    if ((!this.actionItemNote, !this.formData || this.formData == undefined)) {
      notifyError('There was a problem updating this note.')
      return
    }
    this.loading = true

    const payload = {
      actionItemNoteID: _get(this.actionItemNote, 'actionItemNoteID', 0),
      actionItemID: _get(this.actionItemNote, 'actionItemID', 0),
      aspNetUserID: _get(this.actionItemNote, 'aspNetUserID', ''),
      note: this.formData.note ? this.formData.note : ''
    }

    await actionItemNoteStore.dispatch('updateActionItemNote', {
      payload: payload,
      done: () => {
        // auto-close modal
        this.$emit('update:toggle', (this.toggle = false))
        $('#clickclose').click()
        this.loading = false
      }
    })
  }
}
